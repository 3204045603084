import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const HowItWorks = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`text-gray-900 mb-10 poppins py-10 ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="lg:max-w-5xl flex flex-col items-center mx-auto my lg:my-0">
        <h2 className="text-4xl lg:text-8xl montserrat lg:mt-14 relative z-30">
          How it works?
        </h2>
        <span className="text-sm montserrat  relative z-10 poppins lg:hidden">
          (scroll sideways)
        </span>
        <img
          src="/assets/chatbubble.svg"
          className="absolute z-10 lg:w-60 lg:h-60 w-32 h-32 -mt-24 ml-52 lg:ml-[700px] floating-effect2"
          alt="Instructions"
        />
      </div>
      <div className="w-full flex justify-center ">
        <img
          src="assets/howto.png"
          className="hidden lg:block h-auto max-w-full lg:w-4/5 mt-12 "
          alt="Steps"
        />
        {/* Mobile */}

        <div className=" lg:hidden w-72 carousel rounded-box">
          <div className="carousel-item w-full">
            {" "}
            <img
              src="assets/how1.png"
              className="lg:hidden mt-12"
              alt="Steps"
            />
          </div>
          <div className="carousel-item w-full">
            {" "}
            <img
              src="assets/how2.png"
              className="lg:hidden mt-12 "
              alt="Steps"
            />
          </div>
          <div className="carousel-item w-full">
            {" "}
            <img
              src="assets/how3.png"
              className="lg:hidden mt-12"
              alt="Steps"
            />
          </div>
          <div className="carousel-item w-full">
            {" "}
            <img
              src="assets/how4.png"
              className="lg:hidden mt-12"
              alt="Steps"
            />
          </div>
        </div>
        {/* Mobile */}
      </div>

      <section class="py-20">
        <div class=" container mx-auto px-6 lg:px-8 hidden lg:block">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-8 carousel-item">
            <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center ">
              <div class="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                Step 1
              </div>
              <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                Choose your program
              </h2>
              <p class="text-gray-600">
                Once the payment is received, you’ll get an email to set up your
                account.
              </p>
            </div>
            <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center">
              <div class="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                Step 2
              </div>
              <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                Set up
              </h2>
              <p class="text-gray-600">
                Log in and complete your account setup.
              </p>
            </div>
            <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center">
              <div class="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                Step 3
              </div>
              <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                Achieve your full potential
              </h2>
              <p class="text-gray-600">
                Congratulations - your journey has officially begun! You’ll get
                access to everything you need once the challenge starts. See you
                inside!
              </p>
            </div>
          </div>
        </div>
        {/* Mobile */}

        <div className="flex justify-center">
          <div className="ml-1 lg:hidden w-96 carousel rounded-box">
            <div className="carousel-item w-full">
              <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center ">
                <div class="absolute -top-2 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                  Step 1
                </div>
                <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                  Choose your program
                </h2>
                <p class="text-gray-600">
                  Once the payment is received, you’ll get an email to set up
                  your account.
                </p>
              </div>
            </div>
            <div className="carousel-item w-full">
              <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center">
                <div class="absolute -top-2 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                  Step 2
                </div>
                <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                  Set up
                </h2>
                <p class="text-gray-600">
                  Log in and complete your account setup.
                </p>
              </div>
            </div>
            <div className="carousel-item w-full">
              <div class="relative bg-cyan-50 bg-opacity-30 backdrop-blur-sm rounded-2xl drop-shadow-lg p-6 text-white text-center">
                <div class="absolute -top-2 left-1/2 transform -translate-x-1/2 bg-white text-cyan-600 px-4 py-2 rounded-full">
                  Step 3
                </div>
                <h2 class="text-xl font-semibold text-gray-900 mb-2 mt-4">
                  Achieve your full potential
                </h2>
                <p class="text-gray-600">
                  Congratulations - your journey has officially begun! You’ll
                  get access to everything you need once the challenge starts.
                  See you inside!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;
