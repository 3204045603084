import React from "react";
import FaqsCard from "./faqcards";
const FaqsSection = () => {
  const faqsList = [
    {
      q: "How do I receive the program?",
      a: "Once you’ve made your payment, you’ll get access to my app where your full program will be delivered. You’ll receive an email with setup instructions right after purchase.",
    },
    {
      q: "Is this a subscription-based program?",
      a: "No — this is a 6-week challenge. Once you pay, you’ll have full access to the app, your workouts, nutrition targets, and guidance for the full 6 weeks. To keep progressing with new training and updates, you’ll need to join the next challenge.",
    },
    {
      q: "Is the program available worldwide?",
      a: "Yes! The challenge is fully online and available to anyone, anywhere in the world.",
    },
    {
      q: "Can I train from home?",
      a: "Yes! You’ll get both a gym training program and a home workout program. The home version only requires your body weight and resistance bands, making it easy to follow no matter where you are.",
    },
    {
      q: "How do I cancel?",
      a: "Once purchased, there are no refunds. You’ll have full access to the app, including your personalized training and nutrition plan, for the full 6 weeks.",
    },
    {
      q: "Is this good for beginners?",
      a: "Absolutely. My methods are based on simple, effective training — just like the content I post on Instagram. The coaching is designed to be easy to follow while delivering serious results. Whether you’re a beginner or more advanced, you’ll be able to use the program and make progress.",
    },
    {
      q: "Is each month the same?",
      a: "No. Each new challenge includes updated training, progressions, and nutrition guidance. Everything is designed to keep your body adapting and improving — no repeats, no plateaus.",
    },
    ,
  ];

  return (
    <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8 poppins">
      <div className="space-y-3 text-center">
        <h2 className="text-3xl text-gray-800 font-semibold">
          Frequently Asked Questions
        </h2>
        <p className="text-gray-600 max-w-lg mx-auto text-lg">
          Not finding your questions or answers? feel free to contact us.
        </p>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard key={idx} idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};

export default FaqsSection;

{
  /* <div className="flex items-center mt-4 space-x-4 sm:mt-0">
  <ul class="mt-8 flex justify-center gap-6 sm:mt-0 lg:justify-end">
    <li>
      <a
        href="https://www.facebook.com/Aryamsthlm/"
        target="_blank"
        className="poppins text-md z-10 hover:scale-125 hover:text-cyan-500"
      >
        <BsFacebook className="w-6 h-6" />
      </a>
    </li>

    <li>
      <a
        href="https://www.instagram.com/aryam.se/"
        target="_blank"
        className="poppins text-md z-10 hover:scale-125 hover:text-cyan-500"
      >
        <BsInstagram className="w-6 h-6" />
      </a>
    </li>
  </ul>
</div>; */
}
