import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const Services = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // useEffect to update the fade state when the element comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`text-gray-900 mt-10  ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="flex flex-col items-center gap-10 max-w-4xl mx-auto py-10 px-5 montserrat">
        {/* Training - with adjusted focal point */}
        <div className="relative w-full rounded-2xl overflow-hidden shadow-lg">
          <img
            src="/assets/abdulimg1.png"
            alt="Training"
            className="w-full lg:h-96 h-60 object-cover block object-[100%_40%] rounded-2xl" // Added rounded-2xl here
          />
          <div className="absolute inset-0 flex items-center justify-center text-white text-4xl lg:text-5xl font-semibold tracking-tight drop-shadow-md bg-gradient-to-b from-black/20 to-black/40 rounded-2xl">
            {" "}
            {/* Added rounded-2xl here */}
            TRAINING
          </div>
        </div>

        {/* Accountability */}
        <div className="relative w-full rounded-2xl overflow-hidden shadow-lg">
          <img
            src="/assets/abdulimg2.png"
            alt="Accountability"
            className="w-full lg:h-96 h-60 object-cover block rounded-2xl" // Added rounded-2xl here
          />
          <div className="absolute inset-0 flex items-center justify-center text-white text-4xl lg:text-5xl font-semibold tracking-tight drop-shadow-md bg-gradient-to-b from-black/20 to-black/40 rounded-2xl">
            {" "}
            {/* Added rounded-2xl here */}
            ACCOUNTABILITY
          </div>
        </div>

        {/* Nutrition */}
        <div className="relative w-full rounded-2xl overflow-hidden shadow-lg">
          <img
            src="/assets/abdulimg3.png"
            alt="Nutrition"
            className="w-full lg:h-96 h-60 object-cover block rounded-2xl object-[100%_70%]" // Added rounded-2xl here
          />
          <div className="absolute inset-0 flex items-center justify-center text-white text-4xl lg:text-5xl font-semibold tracking-tight drop-shadow-md bg-gradient-to-b from-black/20 to-black/40 rounded-2xl">
            {" "}
            {/* Added rounded-2xl here */}
            NUTRITION
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
