import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

// Home.js
const Home = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  useEffect(() => {
    // Set the target date to March 31st in UAE time (Gulf Standard Time, UTC+4)
    const targetDate = new Date("2025-03-31T00:00:00+04:00");

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );

        setTimeLeft({ days, hours, minutes });
      } else {
        // If the date has passed, set all to 0
        setTimeLeft({ days: 0, hours: 0, minutes: 0 });
      }
    };

    // Update immediately
    updateCountdown();

    // Then update every minute
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, []);

  // Add this scroll function
  const scrollToOffers = () => {
    const offersSection = document.getElementById("our-offers");
    if (offersSection) {
      offersSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      ref={ref}
      className={`text-gray-900 ${
        fadeIn ? "opacity-100" : "opacity-25"
      } transition-opacity duration-100`}
    >
      <div className="relative h-screen overflow-hidden">
        {/* Background Video */}
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
        >
          <source src="/assets/HomeVidGym.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {/* Overlay */}
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-25 z-10"></div>

        {/* Text */}
        <p className="text-6xl lg:text-6xl montserrat absolute top-80 lg:top-36 left-1/2 transform -translate-x-1/2 z-20 text-white text-center">
          HASSONI EXCLUSIVE
        </p>

        {/* Card at the bottom - updated with onClick */}
        <div
          onClick={scrollToOffers}
          className="absolute top-[60%] lg:top-72 left-1/2 transform -translate-x-1/2 z-50 bg-cyan-500 bg-opacity-90 backdrop-blur-sm rounded-lg shadow-lg p-4 sm:p-6 text-center max-w-xs sm:max-w-md w-full cursor-pointer hover:bg-cyan-600 transition-colors duration-300"
        >
          <p className="text-xl sm:text-2xl montserrat text-white font-semibold">
            JOIN TODAY
          </p>
          <p className="text-base sm:text-lg poppins text-white mt-2">
            6 WEEK - SKINNY FAT CHALLENGE
          </p>
          {/* Challenge starts in text */}
          <p className="text-sm sm:text-base poppins text-white mt-3 mb-1">
            Starts in:
          </p>
          {/* Countdown Timer - Apple Style */}
          <div className="flex justify-center space-x-2">
            <div className="flex flex-col items-center">
              <div className="bg-black bg-opacity-30 rounded-md px-2 py-1">
                <span className="text-white font-mono font-bold text-lg">
                  {timeLeft.days.toString().padStart(2, "0")}
                </span>
              </div>
              <span className="text-xs text-white opacity-80 mt-1">DAYS</span>
            </div>
            <div className="text-white font-bold pt-2">:</div>
            <div className="flex flex-col items-center">
              <div className="bg-black bg-opacity-30 rounded-md px-2 py-1">
                <span className="text-white font-mono font-bold text-lg">
                  {timeLeft.hours.toString().padStart(2, "0")}
                </span>
              </div>
              <span className="text-xs text-white opacity-80 mt-1">HOURS</span>
            </div>
            <div className="text-white font-bold pt-2">:</div>
            <div className="flex flex-col items-center">
              <div className="bg-black bg-opacity-30 rounded-md px-2 py-1">
                <span className="text-white font-mono font-bold text-lg">
                  {timeLeft.minutes.toString().padStart(2, "0")}
                </span>
              </div>
              <span className="text-xs text-white opacity-80 mt-1">MIN</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
