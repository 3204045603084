import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const OurOffers = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Fade in effect when component comes into view
  useEffect(() => {
    if (inView) {
      setFadeIn(true);
    }
  }, [inView]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const plans = [
    {
      name: "Female Program",
      desc: "‼ WARNING: This challenge is NOT for you unless you´re ready to build round, sculpted glutes and a sexy, toned body that looks amazing in a bikini! 🏝️",
      price: "?",
      isMostPop: false,
      features: [
        "Personalized Training Program",
        "Custom Nutrition Plan",
        "Individual Macro Targets",
        "Access to Hassoni Exclusive App",
        "Group Chat Community",
        "Weekly Check-in Form",
        "Exercise Demo Videos",
        "Progress Tracking Tools",
        "Meal & Snack Suggestions",
        "Bonus Knowledge Material (Mindset, Nutrition, Lifestyle tips)",
      ],
      link: "https://www.trainerize.me/checkout/hassoniexclusive/Abdul.Hassoni?planGUID=618fd6f56dad4963961d9152f2f2d65d",
    },
    {
      name: "Male Program",
      desc: "‼ WARNING: This challenge is NOT for you unless you´re ready to build a strong, muscular body and a ripped, lean physique that stands out wherever you go! 💪🏽",
      isMostPop: true,
      features: [
        "Personalized Training Program",
        "Custom Nutrition Plan",
        "Individual Macro Targets",
        "Access to Hassoni Exclusive App",
        "Group Chat Community",
        "Weekly Check-in Form",
        "Exercise Demo Videos",
        "Progress Tracking Tools",
        "Meal & Snack Suggestions",
        "Bonus Knowledge Material (Mindset, Nutrition, Lifestyle tips)",
      ],
      link: "https://www.trainerize.me/checkout/hassoniexclusive/Abdul.Hassoni?planGUID=89e7bea55d604939b471447db73eb99d",
    },
  ];

  const handlePackageChange = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section
      id="our-offers"
      ref={ref}
      className={`relative poppins ${
        fadeIn ? "opacity-100" : "opacity-0"
      } transition-opacity duration-1000`}
    >
      <div className="max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
        <div className="relative max-w-xl mx-auto space-y-3 px-4 text-center">
          <p className="text-gray-800 text-2xl poppins">
            🚀 6 week Challenge 🚀{" "}
          </p>
          <p>
            Join a motivated community focused on losing fat while building
            muscle and strength. Get access to tools that help you achieve a
            smaller waistline, more muscle definition, and feel hotter,
            stronger, and more confident, with and without clothes. Spots are
            limited — join the upcoming challenge now!
          </p>
          <p className="text-gray-800 text-2xl poppins pt-10">
            CHOOSE YOUR PROGRAM🔥
          </p>
        </div>
        <div className="mt-10 justify-center sm:flex">
          {plans.map((item, idx) => (
            <div
              key={idx}
              className={`relative flex-1 flex items-stretch flex-col mt-6 sm:mt-0 sm:rounded-xl sm:max-w-md ${
                item.isMostPop ? "bg-white shadow-lg sm:border" : ""
              }`}
            >
              <div className="p-4 py-8 space-y-4 border-b md:p-8">
                <span className="text-cyan-600 text-4xl font-bold flex justify-center">
                  {item.name}
                </span>
                <p>{item.desc}</p>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-3 py-3 rounded-lg w-full text-sm duration-150 text-white bg-cyan-600 hover:bg-cyan-500 active:bg-cyan-700 text-center block"
                >
                  {item.isMostPop
                    ? "18$-USD/Week 6 weeks minimum*"
                    : "18$-USD/Week 6 weeks minimum*"}
                </a>
              </div>
              <ul className="p-4 py-8 space-y-3 md:p-8">
                <li className="pb-2 text-gray-800">
                  <p>Includes</p>
                </li>
                {item.features.map((featureItem, idx) => (
                  <li key={idx} className="flex items-center gap-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-cyan-600"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {featureItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurOffers;
