import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const icons = [
  "/assets/wellness.svg",
  "/assets/dumbbell-01.svg",
  "/assets/yoga-03.svg",
  "/assets/pool.svg",
  "/assets/yoga-02.svg",
  "/assets/weight-scale.svg",
  "/assets/body-weight.svg",
  "/assets/body-part-muscle.svg",
  "/assets/body-part-six-pack.svg",
  "/assets/tape-measure.svg",
];

const words = [
  "Healthier",
  "Stronger",
  "High blood pressure",
  "Happier",
  "Energized",
  "Body composition",
  "Hormonal imbalances",
  "Nutrient deficiencies",
  "Hotter",
  "High Cholesterol",
];

const ScrollingBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`bg-white py-4 overflow-hidden poppins transition-opacity duration-500 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="flex flex-row whitespace-nowrap animate-scroll min-w-max">
        {words.map((word, index) => (
          <span
            key={index}
            className="inline-flex items-center text-black text-2xl font-semibold mx-8"
          >
            <img
              src={icons[index % icons.length]}
              alt="Icon"
              className="w-6 h-6 mr-2"
            />
            {word}
          </span>
        ))}
        {/* Repeat the words for seamless scrolling */}
        {words.map((word, index) => (
          <span
            key={`${index}-copy`}
            className="inline-flex items-center text-black text-xl font-semibold mx-8"
          >
            <img
              src={icons[index % icons.length]}
              alt="Icon"
              className="w-6 h-6 mr-2"
            />
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ScrollingBanner;
